import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCourseSetting(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/course/setting/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOptionList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/option/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateCourseSetting(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("course/setting", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteCourseSetting(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`course/setting?id=${data.id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
